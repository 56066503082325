.button {
  opacity: 1;
  transition: .3s;
}
.button:hover {
  opacity: .8;
  transition: .3s;
}

.ant-carousel .slick-dots li {
  background-color: #eee;
}

.slick-active button  {
  background-color: #ccc !important;
}

div .ant-progress-inner {
  background-color: #ddd;
}


.deleteLink {
  text-decoration: none;
  color: rgba(0,0,0,.4);
}
.deleteLink:hover{
  cursor: pointer;
  color: red;

}