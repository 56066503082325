@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:700&display=swap');

* {
  font-family: 'Open Sans';
}

.insertSectionBelowButton {
  transition: .3s;
  padding-bottom: 20px;
}

.insertSectionBelowButton:hover {
  transition: .3s;
  padding-bottom: 26px;
}
